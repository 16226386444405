import { ReactElement, useEffect, useState } from 'react';
import loader1 from '../../static/loader-1.svg';
import loader2 from '../../static/loader-2.svg';
import loader3 from '../../static/loader-3.svg';

const congressSummariesLoadingSteps = [
  {
    title: 'Analyzing Bill Content',
    details: [
      'Extracting text from document...',
      'Checking for previous summaries...',
      'Identifying key sections and clauses...',
    ],
  },
  {
    title: 'Generating Analysis',
    details: [
      'Locating references to existing laws within this bill...',
      'Reading through existing laws consolidated by United States Code...',
      'Condensing bill into key points...',
    ],
  },
  {
    title: 'Finalizing Output',
    details: [
      'Performing final refinements...',
      'Validating summary consistency...',
      'Preparing response...',
    ],
  },
];

const stateSummariesloadingSteps = [
  {
    title: 'Analyzing Bill Content',
    details: [
      'Extracting text from document...',
      'Checking for previous summaries...',
      'Identifying key sections and clauses...',
    ],
  },
  {
    title: 'Generating Analysis',
    details: ['Condensing bill into key points...'],
  },
  {
    title: 'Finalizing Output',
    details: [
      'Performing final refinements...',
      'Validating summary consistency...',
      'Preparing response...',
    ],
  },
];

export enum Loading {
  billSummaries = 'bill-summaries',
  congressBillSummaries = 'congress-bill-summaries',
  billSearch = 'bill-search',
}

const AnalyzerLoadingAnimation = (props: {
  loading?: Loading;
  message?: string;
}): ReactElement => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentDetail, setCurrentDetail] = useState(0);
  const { loading, message } = props;

  const loadingSteps =
    loading === Loading.congressBillSummaries
      ? congressSummariesLoadingSteps
      : stateSummariesloadingSteps;

  const totalDuration = loading === Loading.congressBillSummaries ? 40000 : 20000;
  const totalDetails = loadingSteps.flatMap((s) => s.details).length;
  const stepDuration = totalDuration / loadingSteps.length;
  const detailDuration = totalDuration / totalDetails;

  useEffect(() => {
    const stepTimer = setInterval(() => {
      setCurrentStep((prev) => {
        if (prev < loadingSteps.length - 1) return prev + 1;
        clearInterval(stepTimer);
        return prev;
      });
    }, stepDuration);

    const detailTimer = setInterval(() => {
      setCurrentDetail((prev) => {
        if (prev < totalDetails - 1) return prev + 1;
        clearInterval(detailTimer);
        return prev;
      });
    }, detailDuration);

    return () => {
      clearInterval(stepTimer);
      clearInterval(detailTimer);
    };
  }, []);

  let detailIndex = 0;

  return (
    <>
      <div id="loader">
        <img id="l3" src={loader1} />
        <img id="l2" src={loader2} />
        <img id="l1" src={loader3} />
        <span className="analyzing-text">{message ?? 'Analyzing bill'}</span>
      </div>

      {loading && loading !== Loading.billSearch && (
        <div className="loadingContainer">
          {loadingSteps.map((step, stepIndex) => {
            const isVisible = stepIndex <= currentStep;
            return (
              <div className={`step blinking`} id={`step-${stepIndex}`} key={stepIndex}>
                <h3 className={`stepTitle ${isVisible ? 'visible' : ''}`}>
                  {step.title}
                </h3>
                <ul className="stepDetails">
                  {step.details.map((detail, idx) => {
                    const itemIndex = detailIndex++;
                    return (
                      <li
                        className={`detail-item ${
                          itemIndex <= currentDetail ? 'visible' : 'hidden'
                        }`}
                        key={idx}
                      >
                        {detail}
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AnalyzerLoadingAnimation;
