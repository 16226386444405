/* eslint-disable react/require-default-props */
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import Loader from 'react-loader';
import { useSelector } from 'react-redux';
import {
  ArrayParam,
  DateParam,
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from 'use-query-params';

import { OrganizationUser } from '@enview/interface/types/OrganizationUser';
import BillList from '../Shared/Lists/BillList';
import Paginator from '../Shared/PageElements/Paginator';
import { SearchActionFilter } from '@enview/interface/types/Bill';

import { SortBy, SortOrder } from '../../models/SortOrder';
import { OptionType } from '../Shared/DropDowns/MultipleSelectDropDown';

import { BillSearchView, SortByParam } from '@enview/interface/types/BillSearch';
import * as Analytics from '../../analytics/TeamAnalytics';
import { BillAPI, TagAPI } from '../../api';
import { getJurisdictionsForTeamMode, getTeamMode } from '../../dux/TeamModeDux';
import SortAndFilterHeader from '../Shared/Lists/SortAndFilterHeader';
import BillTable from '../Shared/Tables/BillTable';
import { buildParamsFromQuery } from '../../helpers/BillSearchHelper';
import { skipToken } from '@reduxjs/toolkit/dist/query';
/* import {
  getDefaultJurisdictionFilter,
  getLegislaturesOptions,
} from '../../helpers/JurisdictionSelectionHelper';
import { State } from '../../dux/@types'; */

const NO_BILL_MESSAGE = "You haven't tracked any bills yet.";
const TEAM_NO_BILL_MESSAGE = 'Your team has not tracked any bills yet.';
const TEAM_SORT_BILL_OPTIONS = [
  new SortOrder(SortBy.RECENT, false),
  new SortOrder(SortBy.RECENT, true),
  new SortOrder(SortBy.ALPHANUMERICAL, false),
  new SortOrder(SortBy.ALPHANUMERICAL, true),
];

type TeamBillsProps = {
  orgUser: OrganizationUser;
  showJurisdictionFilter: boolean;
  filterToJurisdictions?: string[];
  noBillsMessage?: string;
  view?: BillSearchView;
};

// Probably a better way to do this, but I want a cheap way to
// get around the type system and use the results of an ArrayParam as a string[]
const queryParamToArray = (
  arrayParam: (string | null)[] | null | undefined,
): string[] => {
  return arrayParam?.map((s) => (typeof s === 'string' ? s : '')) ?? [];
};

const TeamBills = (props: TeamBillsProps): ReactElement => {
  const {
    orgUser,
    showJurisdictionFilter,
    // filterToJurisdictions,
    noBillsMessage: noBillsMsgFromProps,
    view = BillSearchView.Card,
  } = props;
  const teamModeId = useSelector(getTeamMode);
  const orgJurisdictions = useSelector(getJurisdictionsForTeamMode);

  /*   const initialJurisdictions =
    filterToJurisdictions && filterToJurisdictions.length > 0
      ? filterToJurisdictions.map((jurisAbbr) => jurisAbbr.toLowerCase())
      : []; */

  //jurisdiction options
  /*   const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const legislaturesOptions = getLegislaturesOptions(orgJurisdictions);
  const jurisdictionFilters = getDefaultJurisdictionFilter(
    legislaturesOptions,
    organizationUser,
  ); */

  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    pageSize: withDefault(NumberParam, 20),
    order: withDefault(StringParam, SortByParam.LATEST),
    jurisdictions: withDefault(ArrayParam, []),
    status: ArrayParam,
    sessions: ArrayParam,
    tags: ArrayParam,
    tagFilterMode: StringParam,
    searchActionType: StringParam,
    searchActionFromDate: DateParam,
    searchActionToDate: DateParam,
    sponsors: ArrayParam,
  });

  // Using the sidebar-tracked bills to generate a list of jurisdictions to use in the
  // filter-by-jurisdiction picker. This doesn't have all the data we need to build the
  // bill card/table view, but it's a fast API call and likely in cache already because it's
  // in the sidebar
  const { data: sidebarTrackedBills } =
    BillAPI.endpoints.getSidebarTrackedBills.useQuery(
      teamModeId > 0 ? teamModeId : skipToken,
    );

  const jurisdictionsWithTrackedBills = sidebarTrackedBills
    ? sidebarTrackedBills.map((b) => b.jurisdictionAbbreviation)
    : [];

  const billSearchParams = useMemo(() => {
    // using pageSize as a proxy for "have default query-string params"
    // been applied yet. We can skip an unneeded request if they have not
    if (query && query?.pageSize > 0)
      return buildParamsFromQuery(query, teamModeId, query.pageSize);
    return null;
  }, [query, teamModeId]);
  const { data: billData } = BillAPI.endpoints.getTeamTrackedBills.useQuery(
    billSearchParams
      ? {
          teamId: teamModeId,
          searchParams: billSearchParams,
        }
      : skipToken,
  );

  const trackedBills = billData ?? { data: [], count: 0 };
  const { data: filteredTags } = TagAPI.endpoints.getTags.useQuery(undefined);

  let noBillsMessage = noBillsMsgFromProps;
  if (!noBillsMessage) {
    noBillsMessage =
      teamModeId === orgUser.teamId ? NO_BILL_MESSAGE : TEAM_NO_BILL_MESSAGE;
  }

  const [searching, setSearching] = useState(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [actionFilter, setActionFilter] = useState<SearchActionFilter | undefined>(
    undefined,
  );
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [partySponsorFilters, setPartySponsorFilters] = useState<string[]>([]);
  const [selectedTagFilterOption, setSelectedTagFilterOption] = useState<string>('any');

  const trackFilter = useCallback(() => {
    Analytics.trackFilterWorkspaceBills(
      queryParamToArray(query.jurisdictions),
      teamModeId,
      orgUser,
    );
  }, [query, teamModeId, orgUser]);

  useEffect(() => {
    trackFilter();
  }, [trackFilter, query]);

  useEffect(() => {
    setQuery({ page: query.page });
    window.scrollTo(0, 0);
  }, [query]);

  const filterOptions = useMemo((): OptionType<string>[] => {
    return orgJurisdictions
      .filter((jur) => jurisdictionsWithTrackedBills.includes(jur.abbreviation))
      .map((jur) => {
        return {
          label: jur.name,
          value: jur.abbreviation,
        };
      });
  }, [trackedBills]);

  const tagOptions = useMemo((): OptionType<string>[] | undefined => {
    return filteredTags?.map((tag) => {
      return {
        label: tag.name,
        value: tag.id.toString(),
      };
    });
  }, [filteredTags]);

  // Pagination
  const pageSize = 20;
  const pages = {
    page: query.page,
    total: trackedBills?.count ?? 0,
    pageSize,
  };
  const currentPageOfBills = trackedBills?.data ?? [];

  if (!trackedBills) {
    return <Loader loaded={trackedBills} />;
  }

  const updateOrderSelection = (order: SortOrder): void => {
    setQuery({ page: 1, order: order.getQueryParam() }, 'replaceIn');
  };

  const renderBillList: () => ReactElement = () => {
    return (
      <>
        <SortAndFilterHeader
          filterLabel={'Jurisdiction'}
          filterOptions={showJurisdictionFilter ? filterOptions : undefined}
          selectedFilters={queryParamToArray(query.jurisdictions)}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          setSortOrder={updateOrderSelection}
          showViewDropdown={true}
          sortOptions={TEAM_SORT_BILL_OPTIONS}
          sortOrder={SortOrder.getBillSortOrderFromParam(query.order)}
          tagFilterOptions={tagOptions}
          totalBills={pages.total}
        />
        <BillList bills={currentPageOfBills} noBillsMessage={noBillsMessage} />
        <Paginator onChange={(page) => setQuery({ page })} pages={pages} />
      </>
    );
  };

  const renderBillTable: () => ReactElement = () => {
    const jurisdictionFilterProperties = {
      show: showJurisdictionFilter,
      filterOptions: filterOptions,
      tagFilterOptions: tagOptions,
      setSelectedTags,
      selectedTags,
      actionFilter,
      setActionFilter,
      statusFilters,
      setStatusFilters,
      partySponsorFilters,
      setPartySponsorFilters,
      selectedTagFilterOption,
      setSelectedTagFilterOption,
    };
    return (
      <BillTable
        filterProperties={jurisdictionFilterProperties}
        query={query}
        results={trackedBills}
        searching={searching}
        setQuery={setQuery}
        setSearching={setSearching}
        showFilterProperties={showJurisdictionFilter}
        useServerSidePagination={true}
      />
    );
  };

  return (
    <div className="bill-search-container">
      <div className="results-pane list">
        {view === BillSearchView.Card ? renderBillList() : renderBillTable()}
      </div>
    </div>
  );
};

export default TeamBills;
