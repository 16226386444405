import React, { ReactElement } from 'react';
import './Analyzer.scss';
import DemonstrationSummary from './DemonstrationSummary';
import { Route, Switch } from 'react-router-dom';
import LandingPageControls from './LandingPageControls';
import LandingPageMarketing from './LandingPageMarketing';
import AnalyzerSearchResults from './AnalyzerSearchResults';
import AnalyzerBillSearch from './AnalyzerBillSearch';
import AnalyzerTopBar from './AnalyzerTopBar';

const AnalyzerLandingPageLayout = (): ReactElement => {
  return (
    <div className="landing-container">
      <AnalyzerTopBar />
      <main>
        <LandingPageControls />
        <Switch>
          <Route
            component={DemonstrationSummary}
            path="/analyzer/demonstration/:billId"
          ></Route>
          <Route path="/analyzer/search/:jurisdictionAbbr/:searchText">
            <div className="search-and-summary" id="search-and-summary">
              <span>✨ Search for your own bills and generate summaries</span>
              <AnalyzerBillSearch searchButtonText="Get Started & Analyze" />
            </div>
            <AnalyzerSearchResults />
          </Route>
          <Route path="/analyzer">
            <div className="search-and-summary" id="search-and-summary">
              <span>✨ Search for your own bills and generate summaries</span>
              <AnalyzerBillSearch searchButtonText="Get Started & Analyze" />
            </div>
            <LandingPageMarketing />
          </Route>
        </Switch>
      </main>
      <footer>
        <span>© Plural Policy, Inc.</span>{' '}
        <a href="https://pluralpolicy.com/terms-of-use/">Terms of Use</a>
      </footer>
    </div>
  );
};

export default AnalyzerLandingPageLayout;
