import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosRequestConfig, AxiosError } from 'axios';
import instance from '../config/axiosConfig';
import { getAuthHeader } from '../services/LocalStorageService';
import { logout } from '../dux';

export enum ApiTag {
  BILL_TAG = 'bills',
  BILL_TAG_LIMIT = 'billTagLimit',
  COMMITTEE_TAG = 'committees',
  ORGANIZATION_TAG = 'organizations',
  ORGANIZATION_USER_TAG = 'organizationUsers',
  PERSON_TAG = 'persons',
  TAG_TAG = 'tags',
  TEAM_TAG = 'teams',
  USER_ACTIONS_TAG = 'userActions',
  SAVED_SEARCHES_TAG = 'savedSearches',
  IP_AUTHORIZATION_TAG = 'ipAuth',
  USER_WORKSPACE_SETTINGS_TAG = 'userWorkspaceSettings',
  // Used to invalidate cache on the user- or workspace-level notification
  // settings component when settings change on the "other" level
  NOTIFICATION_SETTINGS_SYNC_TAG = 'notificationSettingsSync',
  POSITION_STATEMENT = 'positionStatement',
  USER_STRIPE_SUBSCRIPTION = 'stripeSubscription',
  DEFAULT_US_JURISDICTIONS = 'defaultUsJurisdictions',
}

// Options that endpoints can specify which will be passed to the base query function.
// Every property on this type should be marked optional
export type QueryOptions = {
  /*Signifies that the query should only be run if we have a valid auth token.
   The query will be skipped and return an `undefined` value for `data` if true
   and no auth token is available
  */
  requiresAuth?: boolean;
};

export type AxiosBaseQuery = BaseQueryFn<{
  url: string;
  method: AxiosRequestConfig['method'];
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
  extraOptions?: QueryOptions;
}>;

/* This is how application-level errors that we throw from the backend
get formatted in the front-end by our axiosBaseQuery below
*/
export type PolicyAppAPIError = {
  status: number;
  data: { statusCode: number; error: string; message: string; errorType: string };
};

/* Type-guard function to allow us to use the message coming from API errors.
RTK-Query will also throw its own errors if an exception occurs at a level other than
backend application code.
*/
export const errorHasData = (
  error: unknown,
): error is {
  status: number;
  data: { statusCode: number; error: string; message: string; errorType: string };
} => (error as PolicyAppAPIError).data !== undefined;

const axiosBaseQuery =
  (): AxiosBaseQuery =>
  async ({ url, method, data, params }, api, extraOptions: QueryOptions) => {
    try {
      if (extraOptions && extraOptions.requiresAuth) {
        if (!getAuthHeader()) {
          return { data: undefined };
        }
      }
      const result = await instance.request({ url, method, data, params });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError;
      /* If we get an "Invalid JWT" message back from the server, it means that our cached auth token is invalid
      (probably expired) and all requests requiring auth are likely to fail. In that case, best thing to do
      is dump that auth token and bring the user back to the login page
      */
      if (
        err.response?.status === 401 &&
        err.response?.data.message.toLowerCase().startsWith('invalid jwt')
      ) {
        api.dispatch(logout());
      }
      return {
        error: { status: err.response?.status, data: err.response?.data },
      };
    }
  };

const baseAPI = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    ApiTag.BILL_TAG,
    ApiTag.COMMITTEE_TAG,
    ApiTag.ORGANIZATION_TAG,
    ApiTag.ORGANIZATION_USER_TAG,
    ApiTag.PERSON_TAG,
    ApiTag.TAG_TAG,
    ApiTag.TEAM_TAG,
    ApiTag.USER_ACTIONS_TAG,
    ApiTag.SAVED_SEARCHES_TAG,
    ApiTag.IP_AUTHORIZATION_TAG,
    ApiTag.USER_WORKSPACE_SETTINGS_TAG,
    ApiTag.NOTIFICATION_SETTINGS_SYNC_TAG,
    ApiTag.BILL_TAG_LIMIT,
    ApiTag.POSITION_STATEMENT,
    ApiTag.USER_STRIPE_SUBSCRIPTION,
    ApiTag.DEFAULT_US_JURISDICTIONS,
  ],
  endpoints: () => ({}),
});

export default baseAPI;
