import React, { ReactElement } from 'react';
import AnalyzerTopBar from './AnalyzerTopBar';
const AnalyzerFullScreenOverlay = (props: { children: ReactElement }): ReactElement => {
  const { children } = props;
  return (
    <>
      <AnalyzerTopBar />
      <main className="login-overlay">{children}</main>
    </>
  );
};

export default AnalyzerFullScreenOverlay;
