import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import icon from '../../static/plural-icon.svg';
import closeIcon from '../../static/x.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { authenticate } from '../../dux';
import { State } from '../../dux/@types';
import { StripeAPI } from '../../api';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
const AnalyzerLoginCard = (): ReactElement => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  // since we are using dispatch() to send the log-in request, we can
  // use redux selectors to get any log-in errors
  const loginError = useSelector((state: State) => state.authentication.loginError);
  const authError = useSelector(
    (state: State) => state.authentication.authErrorMessage,
  );
  const showSuspendedUserMessage = useSelector(
    (state: State) => state.authentication.showSuspendedUserMessage,
  );
  const suspendedUserMessage = (
    <p className="alert alert-danger flash-error">
      <Trans
        components={{
          startedLink: <a href={t('urls.getStartedLink')} />,
          mailLink: <a href={t('urls.mailtoSupport')} />,
        }}
        i18nKey={'error.suspendedUser'}
      />
    </p>
  );

  const loginSubmit = (evt: FormEvent): void => {
    evt.preventDefault();
    dispatch(authenticate(email, password, rememberMe));
  };

  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );

  const { data: userSubscription, isSuccess: loadedSubscription } =
    StripeAPI.endpoints.getSubscriptionForUser.useQuery(
      organizationUser ? undefined : skipToken,
    );

  /* Wait until we've loaded both "user" and "subscription" details, meaning
  that a login attempt was successful. If subscription is active,
  send to home page. Otherwise, send to settings page */
  useEffect(() => {
    if (loadedSubscription) {
      if (userSubscription && userSubscription.status === 'active') {
        history.push('/analyzer#search-and-summary');
      } else {
        history.push('/analyzer/settings');
      }
    }
  }, [loadedSubscription, userSubscription, organizationUser]);

  return (
    <div className="login-card-wrapper">
      <Link to="/analyzer/">
        <img className="close-icon" src={closeIcon} />
      </Link>
      <div className="login-card">
        <img src={icon} />
        <h2>Plural Policy Analyzer</h2>

        <div className="buttons">
          <a
            className="login-button google"
            href={`${process.env.REACT_APP_API_URL}/v2/auth/google`}
            role="button"
          >
            <span className="google-text">Log in with Google</span>
          </a>
        </div>

        <div className="separator">or</div>

        <div className="error-message">
          {loginError && <p className="alert alert-danger flash-error">{loginError}</p>}
          {authError && <p className="alert alert-danger flash-error">{authError}</p>}
          {showSuspendedUserMessage && suspendedUserMessage}
        </div>
        <form className="login-form">
          <input
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            type="text"
            value={email}
          />
          <input
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            type="password"
            value={password}
          />
          <div className="under-login-controls">
            <label>
              <input
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
                type="checkbox"
              ></input>{' '}
              Remember me for 7 days
            </label>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>

          <button className="button" onClick={loginSubmit}>
            Log In
          </button>
        </form>
        <div className="login-link">
          <Link to="/analyzer/signup">Don't have a Plural account? Sign up here</Link>
        </div>
        <div className="terms">
          By using Plural you agree to our{' '}
          <a href="https://pluralpolicy.com/terms-of-use/" target="_blank">
            Terms of Use
          </a>
        </div>
      </div>
    </div>
  );
};

export default AnalyzerLoginCard;
