import {
  AccountRequestSource,
  AccountType,
} from '@enview/interface/types/Organization';
import * as Analytics from '../analytics/AccountAnalytics';
import base from './base';
import { OrganizationUser } from '@enview/interface/types/OrganizationUser';

const onboardingAPI = base.injectEndpoints({
  endpoints: (build) => ({
    requestTrialInvite: build.mutation<
      void,
      {
        firstName: string;
        lastName: string;
        email: string;
        accountType: AccountType;
        captchaToken?: string;
        source?: AccountRequestSource;
      }
    >({
      query: (data) => ({
        url: `/onboarding/request-trial`,
        method: 'POST',
        data: data,
      }),
      onQueryStarted: (data, { queryFulfilled }) => {
        queryFulfilled
          .then(() => {
            Analytics.trackTrialRequestProcess({ step: 1, ...data });
          })
          .catch(() => {
            Analytics.trackTrialRequestError({ step: 1, ...data });
          });
      },
    }),
    verifyEmailConfirmation: build.mutation<
      string,
      { email: string; confirmationCode: string }
    >({
      query: (data) => ({
        url: `/onboarding/verify-email-code`,
        method: 'POST',
        data: data,
      }),
    }),
    singleStepAccountCreation: build.mutation<
      OrganizationUser,
      {
        firstName: string;
        lastName: string;
        email: string;
        verificationCode: string;
        password: string;
        passwordConfirm: string;
        source: string;
      }
    >({
      query: (data) => ({
        url: `/onboarding/single-step-account-creation`,
        method: 'POST',
        data: data,
      }),
    }),
  }),
});

export default onboardingAPI;
