/* eslint-disable react/require-default-props */
import { Bill } from '@enview/interface/types/bills/Bill';
import { ReactElement } from 'react';
import BillBulkActionsDropdown from '../DropDowns/BillBulkActionsDropdown';
import BillViewDropdown from '../DropDowns/BillViewDropdown';
import MultipleSelectDropDown, {
  OptionType,
} from '../DropDowns/MultipleSelectDropDown';
import BillActionFilterDropdown from '../../LegislativeTracking/SearchFilters/BillActionFilterDropdown';
import { SearchActionFilter } from '@enview/interface/types/Bill';
import BillStatusFilterDropdown from '../../LegislativeTracking/SearchFilters/BillStatusFilterDropdown';
import PartySponsorFilterDropdown from '../../LegislativeTracking/SearchFilters/PartySponsorFilterDropdown';
import { getStatusOptionsForJurisdictions } from '../../../helpers/BillSearchHelper';
import { DecodedValueMap, QueryParamConfigMap, SetQuery } from 'use-query-params';
import TagNameFilter from '../Search/TagNameFilter';

export type BillTableFilterProperties = {
  filterLabel?: string;
  selectedFilters?: string[];
  filterOptions?: OptionType<string>[];
  setSelectedFilters?: (selection: string[]) => void;
  tagFilterOptions?: OptionType<string>[];
  selectedTags?: string[];
  setSelectedTags?: (tags: string[]) => void;
  actionFilter?: SearchActionFilter | undefined;
  setActionFilter?: (arg: SearchActionFilter | undefined) => void;
  statusFilters?: string[];
  setStatusFilters?: (filters: string[]) => void;
  partySponsorFilters?: string[];
  setPartySponsorFilters?: (filters: string[]) => void;
  selectedTagFilterOption?: string;
  setSelectedTagFilterOption?: (option: string) => void;
};

type BillTableHeaderProps = {
  results: { data: Bill[]; count: number };
  selectedRows: Bill[];
  showFilterProperties: boolean;
  filterProperties?: BillTableFilterProperties;
  query: DecodedValueMap<QueryParamConfigMap>;
  setQuery: SetQuery<QueryParamConfigMap>;
};

const BillTableHeader = (props: BillTableHeaderProps): ReactElement => {
  const {
    showFilterProperties,
    filterProperties,
    results,
    selectedRows,
    query,
    setQuery,
  } = props;
  const {
    filterLabel = 'Jurisdiction',
    selectedFilters = [],
    filterOptions = [],
    tagFilterOptions,
  } = filterProperties || {};

  const statusOptions = getStatusOptionsForJurisdictions(selectedFilters);

  const selectedStatuses = query.status || [];
  const selectedSessions =
    filterLabel === 'Jurisdiction' ? query?.jurisdictions : query?.sessions || [];
  const selectedSponsorFilters = query.sponsors || [];
  const currentActionFilter =
    query.searchActionType && query.searchActionFromDate && query.searchActionToDate
      ? {
          type: query.searchActionType,
          fromDate: query.searchActionFromDate,
          toDate: query.searchActionToDate,
        }
      : undefined;

  const getQueryUpdate = (selectedValues: string[]) => {
    if (filterLabel === 'Jurisdiction')
      return {
        jurisdictions: selectedValues,
      };
    return { sessions: selectedValues };
  };
  return (
    <div className="row bill-table-header">
      {showFilterProperties && (
        <div className="jurisdiction-filter result-total">
          <div className="filter-group">
            <div className="total-count">
              <span>
                {results?.count ?? 0} bill{results && results?.count == 1 ? '' : 's'}
              </span>
            </div>
            <div className="filter jurisdictions">
              <MultipleSelectDropDown
                currentSelectedValues={selectedSessions}
                onChange={(selectedFilterValues) => {
                  setQuery(getQueryUpdate(selectedFilterValues), 'replaceIn');
                }}
                options={filterOptions}
                title={`Filter by ${filterLabel}`}
              />
            </div>
            {tagFilterOptions && (
              <TagNameFilter
                selectedTagFilterOption={query?.tagFilterMode}
                selectedTags={query?.tags ?? []}
                setSelectedTagFilterOption={(modeSelection) => {
                  setQuery({ tagFilterMode: modeSelection }, 'replaceIn');
                }}
                setSelectedTags={(newSelectedTags) => {
                  setQuery({ tags: newSelectedTags }, 'replaceIn');
                }}
                tagOptions={tagFilterOptions}
              />
            )}
            <BillActionFilterDropdown
              billAction={currentActionFilter}
              setBillAction={(arg: SearchActionFilter | undefined) => {
                if (arg) {
                  setQuery(
                    {
                      searchActionType: arg.type,
                      searchActionFromDate: arg.fromDate,
                      searchActionToDate: arg.toDate,
                    },
                    'replaceIn',
                  );
                } else {
                  setQuery({
                    searchActionType: undefined,
                    searchActionFromDate: undefined,
                    searchActionToDate: undefined,
                  });
                }
              }}
              setSearchChanged={(_b) => {}}
            />
            <BillStatusFilterDropdown
              handleStatusFiltersChange={(filters) => {
                setQuery({ status: filters }, 'replaceIn');
              }}
              status={selectedStatuses}
              statusOptions={statusOptions}
            />
            <PartySponsorFilterDropdown
              handleSponsorFiltersChange={(filters) => {
                setQuery({ sponsors: filters }, 'replaceIn');
              }}
              sponsorFilters={selectedSponsorFilters}
            />
          </div>
          <div className="actions-view-group">
            <BillBulkActionsDropdown bills={selectedRows} />
            <div className="view-dropdown dropdown-container">
              <BillViewDropdown />
            </div>
          </div>
        </div>
      )}

      {!showFilterProperties && (
        <>
          <div className="filtered-group col-lg-10 col-xs-12 result-total">
            <div className="result-dropdown-container">
              <div className="total-count">
                <span>
                  {results?.count ?? 0} bill{results && results?.count == 1 ? '' : 's'}
                </span>
              </div>
              <BillBulkActionsDropdown bills={selectedRows} />
            </div>
          </div>
          <div
            className="view-dropdown col-lg-1 col-xs-12 dropdown-container"
            style={{ paddingLeft: '74px' }}
          >
            <BillViewDropdown />
          </div>
        </>
      )}
    </div>
  );
};

export default BillTableHeader;
