import React from 'react';
import Info from './svg/InfoCircleIcon';
import { Tooltip as ReactTooltip } from 'react-tooltip';

interface InfoPillProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    label: string,
    tooltipText: string | React.ReactElement
}

const InfoPill: React.FC<InfoPillProps> = ({ label, tooltipText, className, ...props }) => {

    return (
        <span
            className={`info-pill badge badge-light d-inline-flex align-items-center gap-1 px-2 py-1 ${className}`}
            {...props}
        >
            <span className="fw-bold mr-1">{label}</span>
            <Info
                id={`${label}-info-pill`}
                size={18}
                className="ms-1 text-light"
            />
            <ReactTooltip
                anchorId={`${label}-info-pill`}
                className="tooltip-content"
                clickable
                delayHide={500}
                place="bottom"
                style={{ maxWidth: "350px" }}
            >
                <div className="info-pill-tooltip-body">{tooltipText}</div>
            </ReactTooltip>
        </span>
    );

}

export default InfoPill
