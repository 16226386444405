import React, { ReactElement, useState } from 'react';
import { BillAutoSummaryResponse } from '@enview/interface/types/dataInsightsAPI/BillAutoSummary';
import './AnalyzerLoader.scss';
import { t } from 'i18next';
import AnalyzerLoadingAnimation, { Loading } from './AnalyzerLoadingAnimation';
import { trackBillAutoSummaryFeedback } from '../../analytics/BillAnalytics';
import { BillDetailed } from '@enview/interface/types/bills/Bill';
import { useForm } from 'react-hook-form';
import FeedbackData, {
  FeedbackType,
} from '@enview/interface/types/actions/FeedbackData';
import { State } from '../../dux/@types';
import { useSelector } from 'react-redux';
import { UserActionAPI } from '../../api';

interface AiSummaryDisplayProps {
  bill: BillDetailed;
  introducedVersionId: number;
  aiSummaryData: BillAutoSummaryResponse | undefined;
  aiSummaryDataFetching: boolean;
  aiSummaryDataError: boolean;
}

type SubmitTextFeedbackFormData = {
  [key: string]: string;
};

const NegativeFeedbackForm = ({
  billId,
  versionId,
  summary,
  trackFeedback,
}: {
  billId: string;
  versionId: number;
  trackFeedback: (answer: boolean) => void;
  summary?: string;
}) => {
  const [isMessageSubmitted, setIsMessageSubmitted] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { errors, register, handleSubmit } = useForm();
  const orgUser = useSelector((state: State) => state.account.organizationUser);
  const [sendTextFeedback, { isError }] =
    UserActionAPI.endpoints.sendFeedback.useMutation();

  const dataFieldName = 'analyzer-summary-feedback-text-area';

  const onSubmitTextFeedback = async (
    data: SubmitTextFeedbackFormData,
  ): Promise<void> => {
    const feedbackData: FeedbackData = {
      feedbackType: FeedbackType.BillSummaryInaccurate,
      organizationUserId: orgUser?.id ?? 0,
      username: orgUser?.email ?? '',
      message: data[dataFieldName],
      metadata: {
        billId: billId,
        billVersionId: versionId,
        summary: summary,
      },
    };

    trackFeedback(false);

    await sendTextFeedback(feedbackData).then(() => {
      if (isError) {
        setShowErrorMessage(true);
        setTimeout(() => setShowErrorMessage(false), 500);
      } else {
        setIsMessageSubmitted(true);
      }
    });
  };

  const onSubmit = handleSubmit(onSubmitTextFeedback);

  return (
    <div className="negative-feedback-form">
      {!isMessageSubmitted && (
        <form onSubmit={onSubmit}>
          <textarea
            className="form-control form-field feedback-text-area my-3"
            id={dataFieldName}
            name={dataFieldName}
            placeholder={t('feedback.feedbackPromptNegativePlaceholder', {
              feedbackType: 'summary',
            })}
            ref={register({ required: true })}
          />
          {errors[dataFieldName] && (
            <div className="error alert alert-danger">
              {t('validation.missingField')}
            </div>
          )}
          <button
            className="button dark-orange"
            disabled={isMessageSubmitted}
            type="submit"
          >
            {t('feedback.submitFeedback')}
          </button>
        </form>
      )}
      <div
        className={`submit-error-container ${isMessageSubmitted ? 'submitted' : ''}`}
      >
        {showErrorMessage && (
          <div className="error alert alert-danger">
            {t('error.genericErrorOccurred')}
          </div>
        )}
        {isMessageSubmitted && <span>{t('feedback.feedbackSuccess')}</span>}
      </div>
    </div>
  );
};

const AiSummaryDisplay = (props: AiSummaryDisplayProps): ReactElement => {
  const {
    bill,
    introducedVersionId,
    aiSummaryData,
    aiSummaryDataError,
    aiSummaryDataFetching,
  } = props;

  const [feedbackSent, setFeedbackSent] = useState(false);
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false);

  const isCongressBill = bill.session.state.abbreviation === 'usa';

  const trackFeedback = (answer: boolean): void => {
    trackBillAutoSummaryFeedback(
      bill.id,
      introducedVersionId,
      answer,
      aiSummaryData?.summary || '(missing)',
      'Analyzer',
    );
    setFeedbackSent(true);
  };

  return (
    <div className="ai-summary">
      <div className="content-box disclaimer">
        AI can make mistakes. Consider validating important information.
      </div>

      {aiSummaryDataFetching && (
        <AnalyzerLoadingAnimation
          loading={
            isCongressBill ? Loading.congressBillSummaries : Loading.billSummaries
          }
        />
      )}

      {aiSummaryDataError && <div>Error loading AI Summary...</div>}
      {!aiSummaryDataFetching && !aiSummaryDataError && aiSummaryData && (
        <div className="content-box ai-summary-content">
          {aiSummaryData.insightsApiError
            ? t(aiSummaryData.errorResourceCode)
            : aiSummaryData.summary}
        </div>
      )}
      {!feedbackSent && aiSummaryData && !aiSummaryDataFetching && (
        <div className="feedback">
          Help us improve. Was this summary accurate?{' '}
          <a
            className="green-text"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              trackFeedback(true);
            }}
          >
            Yes
          </a>{' '}
          or{' '}
          <a
            className="red-text"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setIsNegativeFeedback(true);
            }}
          >
            No
          </a>
        </div>
      )}
      {!feedbackSent && isNegativeFeedback && (
        <NegativeFeedbackForm
          billId={bill.id}
          summary={aiSummaryData?.summary}
          trackFeedback={trackFeedback}
          versionId={introducedVersionId}
        />
      )}
      {feedbackSent && <div className="feedback">Thank you for your feedback!</div>}
    </div>
  );
};

export default AiSummaryDisplay;
