import React, { ReactElement, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from '../../static/plural-policy-analyzer.svg';
import { State } from '../../dux/@types';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../../dux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const AnalyzerTopBar = (): ReactElement => {
  const authToken = useSelector((state: State) => state.authentication.authToken);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 576px)').matches,
  );
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia('(max-width: 576px)').matches);
    };

    const mediaQuery = window.matchMedia('(max-width: 576px)');
    mediaQuery.addEventListener('change', handleResize);

    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    setMenuOpen(false);
    history.push('/analyzer');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [menuOpen]);

  return (
    <aside className="top-nav">
      <Link className="logo" to="/analyzer/">
        <img alt="Plural Policy Analyzer" src={logo} />
      </Link>

      {isMobile ? (
        <>
          <button className="menu-toggle" onClick={toggleMenu}>
            {menuOpen ? (
              <FontAwesomeIcon icon={faTimes} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>

          <div className={`drawer-menu ${menuOpen ? 'open' : ''}`}>
            <nav className="menu-items">
              {!authToken ? (
                <>
                  <Link
                    className="button"
                    onClick={() => setMenuOpen(false)}
                    to="/analyzer/login"
                  >
                    Log in
                  </Link>
                  <Link
                    className="button dark-orange"
                    onClick={() => setMenuOpen(false)}
                    to="/analyzer/signup"
                  >
                    Sign Up
                  </Link>
                </>
              ) : (
                <>
                  <a className="button" href="#" onClick={handleLogout}>
                    Log Out
                  </a>
                  <Link
                    className="button dark-orange"
                    onClick={() => setMenuOpen(false)}
                    to="/analyzer/settings"
                  >
                    Settings
                  </Link>
                </>
              )}
            </nav>
          </div>
        </>
      ) : (
        <span className="nav-links">
          {!authToken ? (
            <>
              <Link className="button" to="/analyzer/login">
                Log in
              </Link>
              <Link className="button dark-orange" to="/analyzer/signup">
                Sign Up
              </Link>
            </>
          ) : (
            <>
              <a className="button" href="#" onClick={handleLogout}>
                Log Out
              </a>
              <Link className="button dark-orange" to="/analyzer/settings">
                Settings
              </Link>
            </>
          )}
        </span>
      )}
    </aside>
  );
};

export default AnalyzerTopBar;
