import React, { ReactElement, Dispatch, SetStateAction } from 'react';
import {
  ContainerNode,
  RichTextNode,
  RichTextNodeType,
  TextNode,
  Attributes,
} from '@enview/interface/types/actions/UserAction';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Editor, Text, Element as SlateElement, Transforms } from 'slate';
import { useSlate, useSelected, useFocused } from 'slate-react';
import { Spinner } from 'react-bootstrap';
import get from 'lodash-es/get';
import Info from '../../../../components/svg/InfoCircleIcon';
import { Trans, useTranslation } from 'react-i18next';
import * as BillAnalytics from '../../../../analytics/BillAnalytics';
import GateTooltip, { GateTooltipFeature } from '../../Tooltips/TooltipGate';
import { checkPermission } from '../../../../dux';
import { useDispatch } from 'react-redux';
import { g as abilityGlossary } from '../../../../config/ability';
import { deserializeToBillSummaryNodes } from '@enview/interface/utils/RichTextAnnotation';

export const Element = (props: {
  attributes: Attributes;
  children: string;
  element: SlateElement;
}): ReactElement => {
  const { attributes, children, element } = props;
  switch ((element as ContainerNode).type) {
    case RichTextNodeType.BULLETED_LIST:
      return <ul {...attributes}>{children}</ul>;
    case RichTextNodeType.NUMBERED_LIST:
      return <ol {...attributes}>{children}</ol>;
    case RichTextNodeType.LIST_ITEM:
      return <li {...attributes}>{children}</li>;
    case RichTextNodeType.MENTION:
      return <Mention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Mention = (props: {
  attributes: Attributes;
  children: string;
  element: RichTextNode;
}): ReactElement => {
  // ex. @sru to @Sruthi Vedantham
  const { attributes, children, element } = props;

  const selected = useSelected();
  const focused = useFocused();

  let className =
    selected && focused ? 'rich-text-mention selected' : 'rich-text-mention';
  // See if our empty text child has any styling marks applied and apply those
  if (((element as ContainerNode).children[0] as TextNode).bold) {
    className += ' bold';
  }
  if (((element as ContainerNode).children[0] as TextNode).italic) {
    className += ' italic';
  }

  return (
    <span {...attributes} className={className} contentEditable={false}>
      @{(element as ContainerNode).character}
      {children}
    </span>
  );
};

// button for 'inline' elements
export const EditorButton = (props: {
  format: string;
  icon: ReactElement;
  disabled?: boolean;
}): ReactElement => {
  const { format, icon, disabled } = props;
  // useSlate(): get current editor object from React context
  const editor = useSlate();
  return (
    <button
      className={
        isMarkActive(editor, format)
          ? 'rich-text-button bar active'
          : 'rich-text-button bar'
      }
      disabled={disabled}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      type="button"
    >
      {icon}
    </button>
  );
};

export const toggleMark = (editor: Editor, format: string): void => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: Editor, format: string): boolean => {
  // get marks that would be added to text at current selection
  const marks = Editor.marks(editor);
  return marks ? get(marks, format) === true : false;
};

// button for 'block' elements like ex. bulleted and numbered lists
export const BlockButton = (props: {
  format: string;
  icon: ReactElement;
  disabled?: boolean;
}): ReactElement => {
  const { format, icon, disabled } = props;
  const editor = useSlate();
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  );
  return (
    <button
      className={isActive ? 'rich-text-button bar active' : 'rich-text-button bar'}
      disabled={disabled}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      type="button"
    >
      {icon}
    </button>
  );
};

export const GenerateButton = (props: {
  icon: ReactElement;
  summary?: string | boolean;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setButtonPress: Dispatch<SetStateAction<boolean>>;
  billId: string | undefined;
}): ReactElement => {
  const { icon, summary, loading, setLoading, setButtonPress, billId } = props;
  const editor = useSlate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const canViewSummary = () => {
    return dispatch(
      checkPermission(abilityGlossary.VIEW, abilityGlossary.BILL_SUMMARY, true),
    );
  };

  const tooltipText = `
    ${t('bill.sidebar.billSummary.richTextTooltipInstruction')}<br /><br />
    ${t('bill.sidebar.billSummary.tooltipText')}
  `;

  return (
    <span style={{ display: 'flex' }}>
      <button
        className="rich-text-button summary"
        id="generate"
        onMouseDown={(event) => {
          if (canViewSummary()) {
            event.preventDefault();
            setButtonPress(true);
            if (billId) {
              BillAnalytics.trackBillSummary(billId, 'Activity Feed');
            }
            if (typeof summary === 'boolean') {
              setLoading(true);
            } else {
              getSummary(editor, summary, setLoading);
            }
          }
        }}
        type="button"
      >
        {icon}
      </button>
      {!canViewSummary() && (
        <GateTooltip
          accountType="pro"
          anchorId="generate"
          customContent={
            <Trans
              components={{
                aiLink: <a href={t('urls.aiBillSummary')} target="_blank" />,
                gate: <p className="tooltip-header" />,
              }}
              i18nKey="featureGating.tooltipText.aiBillSummaryComment"
              key="featureGating.tooltipText.aiBillSummaryComment"
            />
          }
          featureName={GateTooltipFeature.BillSummary}
          place="top"
        />
      )}
      <span id="bill-summary-tooltip">
        <Info />
      </span>
      <ReactTooltip
        anchorId="bill-summary-tooltip"
        className="tooltip-content"
        clickable
        delayHide={500}
        html={tooltipText}
        place="bottom"
        style={{ maxWidth: '300px' }}
      />
      {loading && typeof summary === 'boolean' ? (
        <span className="spinner">
          <Spinner animation="border" role="status" size="sm" />
        </span>
      ) : (
        <></>
      )}
    </span>
  );
};

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];

export const toggleBlock = (editor: Editor, format: string): void => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? 'align' : 'type',
  );

  const isList = LIST_TYPES.includes(format);

  // Transform functions can change editor's value
  // usually apply a single operation to zero or more nodes
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      !Text.isText(n) &&
      LIST_TYPES.includes(n.type || '') &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });

  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive
        ? RichTextNodeType.PARAGRAPH
        : isList
        ? RichTextNodeType.LIST_ITEM
        : undefined,
    };
  }
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format as RichTextNodeType, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const getSummary = (
  editor: Editor,
  summary: string | boolean | undefined,
  setLoading: Dispatch<SetStateAction<boolean>>,
): void => {
  // TODO: fix awkward space above all of this
  if (typeof summary === 'string' || summary === undefined) {
    const deserialized = deserializeToBillSummaryNodes(
      summary ? summary : 'No Summary Available',
    );

    const initialValue: ContainerNode[] = [
      {
        type: RichTextNodeType.PARAGRAPH,
        children: [{ text: '' }],
      },
    ];

    setLoading(false);
    Transforms.insertNodes(editor, deserialized);
    // skip to next line for the user
    Transforms.insertNodes(editor, initialValue);
    Transforms.move(editor);
  }
};

const isBlockActive = (editor: Editor, format: string, blockType = 'type'): boolean => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      // convert a range into a non-hanging one
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        get(n, blockType) === format,
    }),
  );
  return !!match;
};
