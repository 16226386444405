import React, { lazy, ReactElement, Suspense, useEffect } from 'react';
import FullStory from 'react-fullstory';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import FocusLayout from '../components/FocusLayout';
import LoggedOutRouter from '../components/LoggedOutRouter';
import LoggedInRouter from '../components/LoggedInRouter';
import { OnboardingStage } from './Registration/RegistrationHelper';
import { getOrganizationUser, getSupportedJurisdictions } from '../dux/AccountDux';
import { getUserOnboardingStage } from '../dux/RegistrationDux';
import { State } from '../dux/@types';
import trackPageViewed from '../analytics/AppAnalytics';
import { AbilityContext } from '../components/Can';
import ability from '../config/ability';
import PrimaryLayout from './PrimaryView/PrimaryLayout';
import { loginSucceeded, logout } from '../dux';

const RegistrationContainer = lazy(
  () => import('./Registration/RegistrationContainer'),
);
const Onboarding = lazy(() => import('./Registration/Onboarding/Onboarding'));

interface LocationState {
  fromPath: string;
}

const RootLayout = (): ReactElement => {
  const location = useLocation<LocationState>();
  const prevPath = location.state?.fromPath ? location.state.fromPath : '';
  const activePath = location.pathname;
  const authToken = useSelector((state: State) => state.authentication.authToken);
  const loginSuccess = useSelector(loginSucceeded);
  const onboardingStage = useSelector(
    (state: State) => state.registrations.onboardingStage,
  );
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );
  const openRestrictionModal = useSelector(
    (state: State) => state.modals.modalOpenLoginRestriction,
  );
  const openOptionalLoginModal = useSelector(
    (state: State) => state.modals.modalOpenOptionalLogin,
  );
  const authErrorMessage = useSelector(
    (state: State) => state.authentication.authErrorMessage,
  );
  const userInitiatedLogOut = useSelector(
    (state: State) => state.teamMode.userInitiatedLogOut,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  let broadcastChannel = new BroadcastChannel('auth');

  // Looks like there a couple different areas of the code that could initiate a
  // redirect to the login page - likely a refactor could be valuable here. In the
  // mean time, make sure that requests to the forgot password page aren't intercepted
  useEffect(() => {
    if (userInitiatedLogOut && !activePath.includes('/forgot-password')) {
      history.replace('/login');
    }
  }, [history, userInitiatedLogOut]);

  useEffect(() => {
    if (activePath !== '/login' && !authToken) {
      if (location.search) {
        localStorage.setItem('activePath', `${activePath}${location.search}`);
      } else {
        localStorage.setItem('activePath', activePath);
      }
    }
  });

  useEffect(() => {
    if (authToken) {
      // Take note! These actions both will result in API calls, which can return in any order, possibly
      // with a substantial gap between. Be careful about testing the resulting state
      dispatch(getOrganizationUser());
      dispatch(getUserOnboardingStage());
    }
  }, [dispatch, authToken]);

  useEffect(() => {
    if (organizationUser) {
      // Get supported jurisdictions here, because child components almost always need it, to avoid several dispatches
      dispatch(getSupportedJurisdictions());
    }
  }, [dispatch, organizationUser]);

  useEffect(() => {
    // Register navigation changes with Segment for analytics
    trackPageViewed(activePath, activePath, prevPath);
  }, [activePath]);

  // sets up the broadcast message channel to logout the user across multiple tabs/windows
  useEffect(() => {
    if (userInitiatedLogOut && !activePath.includes('/forgot-password')) {
      // inform other tabs to logout so the Redux state doesn't get re-saved to local storage
      broadcastChannel?.postMessage('signout');
      broadcastChannel?.close();
    } else if (authToken) {
      if (!broadcastChannel) {
        broadcastChannel = new BroadcastChannel('auth');
      }
      broadcastChannel.onmessage = (message) => {
        if (message?.data === 'signout') {
          dispatch(logout());
        }
      };
    }
    return () => {
      if (broadcastChannel) {
        broadcastChannel.onmessage = null;
        broadcastChannel.close();
      }
    };
  }, [authToken, userInitiatedLogOut]);

  const loggedOutPath = localStorage.getItem('activePath');

  const getRedirectUrl = (): string | null | undefined => {
    if (
      loginSuccess &&
      activePath !== loggedOutPath &&
      !loggedOutPath?.includes('/sign-up') &&
      loggedOutPath !== '/' &&
      loggedOutPath !== '/create-account' &&
      loggedOutPath !== '/free-trial'
    ) {
      return loggedOutPath;
    }
    if (
      loginSuccess &&
      (loggedOutPath === '/' ||
        loggedOutPath === '/mfa-registration' ||
        loggedOutPath === '/mfa-verification' ||
        loggedOutPath?.includes('/sign-up'))
    ) {
      return '/legislative-tracking';
    }
    return undefined;
  };

  const getLayout = (): ReactElement => {
    if (userInitiatedLogOut && !activePath.includes('/forgot-password')) {
      return <LoggedOutRouter activePath="/login" />;
    }

    const registrationComplete =
      onboardingStage && onboardingStage === OnboardingStage.COMPLETE;
    if (onboardingStage && !registrationComplete) {
      return (
        <RegistrationContainer>
          <Onboarding />
        </RegistrationContainer>
      );
    }
    if (authToken && organizationUser && registrationComplete) {
      return (
        <PrimaryLayout organizationUser={organizationUser}>
          <LoggedInRouter
            activePath={activePath}
            loginRedirectUrl={getRedirectUrl()}
            orgUser={organizationUser}
          />
        </PrimaryLayout>
      );
    }

    if (authToken && (!onboardingStage || !organizationUser)) {
      return (
        <FocusLayout>
          {!authErrorMessage && <p>Loading...</p>}
          {authErrorMessage && <p>{authErrorMessage}</p>}
        </FocusLayout>
      );
    }
    return (
      <LoggedOutRouter
        activePath={activePath}
        openOptionalLoginModal={openOptionalLoginModal}
        openRestrictionModal={openRestrictionModal}
      />
    );
  };

  return (
    <AbilityContext.Provider value={ability}>
      <div>
        <FullStory org="HFHA9" />
        <Suspense fallback={<TopBarProgress />}>{getLayout()}</Suspense>
      </div>
    </AbilityContext.Provider>
  );
};

export default RootLayout;
