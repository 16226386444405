import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import './DemonstrationSummary.scss';
import BillText from '../BillView/BillText/BillText';
import AiSummaryDisplay from './AiSummaryDisplay';
import { BillDetailed } from '@enview/interface/types/bills/Bill';
import { BillAutoSummaryResponse } from '@enview/interface/types/dataInsightsAPI/BillAutoSummary';
import SingleSelectDropDown, {
  OptionType,
} from '../Shared/DropDowns/SingleSelectDropDown';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import InfoPill from '../../components/InfoPill';

interface AnalyzerBillSummaryProps {
  bill: BillDetailed;
  aiSummaryData: BillAutoSummaryResponse | undefined;
  aiSummaryDataFetching: boolean;
  aiSummaryDataError: boolean;
  introducedVersionId: number;
  nav?: boolean;
}

const summaryTab = 'summary-tab-selector';
const billTextTab = 'text-tab-selector';

const AnalyzerBillSummary = (props: AnalyzerBillSummaryProps): ReactElement => {
  const {
    bill,
    aiSummaryData,
    aiSummaryDataError,
    aiSummaryDataFetching,
    introducedVersionId,
    nav = false,
  } = props;
  const [selectedTab, setSelectedTab] = useState(summaryTab);
  const [selectedVersionId, setSelectedVersionId] = useState(
    bill?.versions.at(0)?.id || -1,
  );

  const history = useHistory();
  const { t } = useTranslation();

  const selectedVersion = bill.versions.find((v) => v.id === selectedVersionId);

  const summaryDisplayRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    summaryDisplayRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);

  const versionOptions: OptionType<number>[] = bill.versions.map((version) => {
    return {
      label: version.versionPostedDate
        ? `${version.versionName} (${moment
            .utc(version.versionPostedDate)
            .format('M/D/YYYY')})`
        : version.versionName,
      value: version.id,
    };
  });

  const betaTooltipText = (
    <Trans
      components={{
        header: <p className="pill-header" />,
        group: <p />,
        footnote: <p className="footnote" />,
      }}
      i18nKey="analyzer.betaTooltipText"
    />
  );

  const isCongressBill = bill.session.state.abbreviation === 'usa';

  return (
    <div ref={summaryDisplayRef}>
      {nav && (
        <button className="back-button" onClick={() => history.push('/analyzer')}>
          <FontAwesomeIcon className="icon" icon={faArrowLeft} /> Back
        </button>
      )}
      <div className="number-and-controls">
        <h2>
          {`${bill.session.state.name} ${bill.number}`} <br /> <span>{bill.name}</span>
        </h2>
        <span className="bill-metadata">
          <span className="pill dark-green session">{bill.session.displayName}</span>
          <span className="pill dark-green bill-status">
            {bill?.status || 'Introduced'}
          </span>
          <span className="bill-version">
            Bill Version:
            <SingleSelectDropDown
              defaultLabel={t('bill.header.billVersionDefaultLabel')}
              disabled
              id="bill-summary-version-detail"
              onSelect={(opt) => {
                const value = opt.value;
                if (!isNaN(value)) setSelectedVersionId(value);
              }}
              options={versionOptions}
              selectedValue={selectedVersionId}
            />
            <ReactTooltip
              anchorId="bill-summary-version-detail"
              children={t('analyzer.versionSelect')}
              className="tooltip-content"
              clickable
              delayHide={500}
              place="bottom"
              style={{ maxWidth: '300px' }}
            />
          </span>
        </span>
      </div>
      <div className="bill-title">{bill.name}</div>
      <div className="tab-links">
        <a
          className={selectedTab === summaryTab ? 'button dark-orange' : 'button light'}
          href="#"
          id="summary-tab-selector"
          onClick={(e) => {
            e.preventDefault();
            setSelectedTab(summaryTab);
          }}
        >
          AI Bill Analysis
          {isCongressBill && (
            <InfoPill className="ml-3" label="BETA" tooltipText={betaTooltipText} />
          )}
        </a>
        <a
          className={
            selectedTab === billTextTab ? 'button dark-orange' : 'button light'
          }
          href="#"
          id="text-tab-selector"
          onClick={(e) => {
            e.preventDefault();
            setSelectedTab(billTextTab);
          }}
        >
          Bill Text
        </a>
      </div>
      <div className="tab-content">
        {selectedTab === summaryTab && (
          <AiSummaryDisplay
            aiSummaryData={aiSummaryData}
            aiSummaryDataError={aiSummaryDataError}
            aiSummaryDataFetching={aiSummaryDataFetching}
            bill={bill}
            introducedVersionId={introducedVersionId}
          />
        )}
        {selectedTab === billTextTab && selectedVersion && (
          <>
            {' '}
            {selectedVersion.diffText ? (
              <BillText
                annotationsEnabled={false}
                bill={bill}
                billVersionId={selectedVersion.id}
                debug={false}
                diff={selectedVersion.diffText ?? ''}
                showChanges={true}
              />
            ) : (
              <div>
                Plural was unable to embed this bill's text from the source. View this
                bill on its{' '}
                <a
                  href={selectedVersion.sourceDocUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  original website
                </a>{' '}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AnalyzerBillSummary;
