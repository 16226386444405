import React, { ReactElement, useEffect } from 'react';
import banner from '../../static/trusted-by-banner.png';
import { Link } from 'react-router-dom';

const LandingPageMarketing = (): ReactElement => {
  // Implement scroll-to-section in a cheap way for links to #faq
  useEffect(() => {
    const hash = location.hash.slice(1); // remove # symbol
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);
  return (
    <>
      <div className="landing-below-fold two-column-section">
        <div className="right">
          <h3>How it works</h3>
          <ol>
            <li>
              <strong>Get started:</strong> Create an account
            </li>
            <li>
              <strong>Search:</strong> Select a legislative jurisdiction and enter a
              bill number
            </li>
            <li>
              <strong>Analyze:</strong> Click ‘Analyze’ and Plural will generate an
              analysis of what the introduced legislation is proposing to do{' '}
            </li>
          </ol>
        </div>
      </div>
      <div className="trusted-by">
        <div>Trusted By</div>
        <img
          alt="Company logos for Google, the Associated Press, Columbia University, Secure Elections Project, and Nextgen America"
          src={banner}
        />
      </div>
      <div className="features-benefits">
        <h2>Analyze thousands of bills in less than 30 seconds</h2>
        <div className="color-emphasis">for just $29 per month</div>
        <div className="two-column-section">
          <div className="left">
            <h3>Search</h3>
            <p>
              With the largest database of public policy, you’ll be able to search,
              find, and read any bill in the country at the federal and state level.
            </p>
          </div>
          <div className="right">
            <h3>Analyze</h3>
            <p>
              Use our AI-powered features to analyze and summarize any bill. Use our
              Analyzer to covert long, dense bill text into digestible short summaries
              that retain the key details of the bill.
            </p>
          </div>
        </div>
        <div className="buttons">
          <Link className="button dark-orange" to="/analyzer/signup">
            Sign Up
          </Link>
          <a className="button dark-green" href="https://pluralpolicy.com">
            Learn more about Plural
          </a>
        </div>
      </div>
      <div className="faq" id="faq">
        <h2>Frequently Asked Questions</h2>

        <h3>
          Why is our Analyzer better than ChatGPT, Gemini, and other AI Assistants?
        </h3>
        <p>
          Using Plural’s AI policy analyzer offers distinct advantages over general AI
          assistants like ChatGPT or Gemini. Our tool is tailored specifically for
          legislative analysis, providing accurate, concise, and detailed insights
          grounded in comprehensive and primary legislative datasets. Unlike general AI
          models, it is designed to understand legislative nuances, such as contextual
          changes to laws, and avoids reliance on biased third-party data. In addition,
          general AI assistants have character limits so in many cases it’s not possible
          to even share the full bill text.{' '}
        </p>

        <h3>
          What's the difference between Plural's Analyzer and Plural's Bill Tracking and
          Intelligence software?
        </h3>
        <p>
          Plural’s Bill Tracking and Intelligence software is a robust, all-in-one
          platform that empowers users to monitor, research, and manage legislative
          activity across jurisdictions. It provides unparalleled access to
          comprehensive legislative data, custom alerts, and tools for deep policy
          analysis, making it essential for policy professionals. Plural’s AI Analyzer
          is a specialized feature within this platform that streamlines the process by
          summarizing legislative texts into accurate, concise, and actionable insights.
          While the Bill Tracking software offers the full power of legislative
          intelligence, the AI Analyzer enhances efficiency by making policy analysis
          quicker and more accessible.
        </p>

        <h3>Who should consider purchasing Plural’s Analyzer?</h3>
        <p>
          If you’re an individual that needs to read and understand multiple pieces of
          legislation for work, school, or advocacy, Plural’s AI-powered Policy Analyzer
          is an invaluable tool. It’s perfect for policy professionals, students,
          researchers, and advocates who need concise, accurate, and context-specific
          analysis of legislative texts. By quickly breaking down complex bills into
          actionable information, the Analyzer saves time and ensures you stay informed
          without getting lost in legal jargon. If you’re part of a larger policy
          advocacy team or government relations team, you may want to take a look at our
          AI-Powered Bill Tracking and Intelligence software.
        </p>

        <h3>What policy can I analyze using this tool?</h3>
        <p>
          Right now, our Analyzer focuses on US legislation. The Analyzer can summarize
          bills in U.S. states excluding Tennessee, Massachusetts, Hawaii, and the
          District of Columbia. Check back soon for updates here! Congress bills are now
          available to view in Beta.
        </p>
      </div>
    </>
  );
};

export default LandingPageMarketing;
