import { Turnstile } from '@marsidev/react-turnstile';
import { ReactElement } from 'react';

type TurnstileCaptchaProps = {
  onSuccess: (token: string) => void;
};

const fallbackSiteDummyKey = '1x00000000000000000000AA';
const defaultKeyNames = ['%REACT_APP_TURNSTILE_SITE_KEY%', 'testing'];

const TurnstileCaptcha = (props: TurnstileCaptchaProps): ReactElement => {
  const { onSuccess } = props;

  const getSiteKey = (): string => {
    const token = document
      ?.querySelector('[property="turnstile-key"]')
      ?.getAttribute('content');
    return !token || defaultKeyNames.includes(token) ? fallbackSiteDummyKey : token;
  };

  return (
    <Turnstile
      onSuccess={onSuccess}
      options={{
        theme: 'light',
      }}
      siteKey={getSiteKey()}
    />
  );
};

export default TurnstileCaptcha;
