import { BillSidebarSummary } from '@enview/interface/types/bills/Bill';
import { faFile } from '@fortawesome/fontawesome-free-regular';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { BillAPI } from '../../../../api';
import { getTeamMode } from '../../../../dux';
import NavSidebarSection from '../NavSidebarSection';
import { SidebarProps } from './SidebarProps';
import { skipToken } from '@reduxjs/toolkit/dist/query';

const BILL_DETAILS_PATH = '/legislative-tracking/bill/details/';
const NO_TRACKED_BILLS_MESSAGE = 'No tracked bills';

const sortBillsForSidebar = (bills: BillSidebarSummary[]) => {
  const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: 'base',
  });
  return bills.sort((a, b) => {
    return collator.compare(
      `${a.jurisdictionAbbreviation}${a.alphanumSort}`,
      `${b.jurisdictionAbbreviation}${b.alphanumSort}`,
    );
  });
};

const BillsSidebarSection = (props: SidebarProps): ReactElement => {
  const { onNavClick } = props;
  const teamModeId = useSelector(getTeamMode);
  const { data: trackedBills } = BillAPI.endpoints.getSidebarTrackedBills.useQuery(
    teamModeId > 0 ? teamModeId : skipToken,
  );

  const billList = trackedBills ?? [];

  const sortedBills = sortBillsForSidebar([...billList]);
  const mappedBills =
    sortedBills.length === 0 ? (
      <div className="tracked-bill-empty">{NO_TRACKED_BILLS_MESSAGE}</div>
    ) : (
      sortedBills.map((bill) => {
        const { jurisdictionAbbreviation, number, userAlias } = bill;
        const billName = userAlias ? `${bill.number} (${userAlias})` : bill.number;
        const tooltip = `${number} - ${bill.name}${userAlias ? ` (${userAlias})` : ''}`;
        return (
          <div key={bill.id}>
            <NavLink
              activeClassName="active-nav-link"
              className="d-flex sub-item"
              onClick={onNavClick}
              to={`${BILL_DETAILS_PATH}${bill.id}`}
            >
              <span id={bill.id}>
                <span>
                  {jurisdictionAbbreviation === 'usa'
                    ? 'FED'
                    : jurisdictionAbbreviation.toUpperCase()}
                </span>
                <span>&nbsp;</span>
                <span>{billName}</span>
                <br />
              </span>
              <ReactTooltip
                anchorId={bill.id}
                className="link-tooltip tooltip-content"
                delayShow={200}
                place="right"
              >
                {tooltip}
              </ReactTooltip>
            </NavLink>
          </div>
        );
      })
    );

  const getCardBody = (): ReactElement => {
    return <>{mappedBills}</>;
  };

  return (
    <NavSidebarSection
      cardBody={getCardBody()}
      eventKey="BILLS"
      header="Bills"
      icon={<FontAwesomeIcon icon={faFile as IconProp} />}
    />
  );
};

export default BillsSidebarSection;
