import React, { ReactElement } from 'react';
import icon from '../../static/plural-icon.svg';
import { Link } from 'react-router-dom';
const AnalyzerSubscriptionOverlay = (): ReactElement => {
  return (
    <>
      <main className="login-overlay">
        <div className="login-card-wrapper">
          <div className="login-card">
            <img src={icon} />
            <h2>Subscription Required</h2>
            <p>
              This feature requires an active subscription. You can set up a
              subscription on the <Link to="/analyzer/settings">Settings Page</Link>.
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default AnalyzerSubscriptionOverlay;
