import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import icon from '../../static/plural-icon.svg';
import closeIcon from '../../static/x.svg';
import emptyDot from '../../static/orange-dot.svg';
import filledDot from '../../static/orange-dot-filled.svg';
import { Link, useHistory } from 'react-router-dom';
import TurnstileCaptcha from '../Registration/Turnstile/TurnstileCaptcha';
import { OnboardingAPI } from '../../api';
import {
  AccountRequestSource,
  AccountType,
} from '@enview/interface/types/Organization';
import { t } from 'i18next';
import { authenticate } from '../../dux';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '../../dux/@types';
const AnalyzerSignupCard = (): ReactElement => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [termsAcceptance, setTermsAcceptance] = useState(false);
  const [token, setToken] = useState<string>();
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const dispatch = useDispatch();
  const history = useHistory();

  const authToken = useSelector((state: State) => state.authentication.authToken);
  const organizationUser = useSelector(
    (state: State) => state.account.organizationUser,
  );

  const [
    createTrialInvite,
    { isSuccess: inviteCreationSucceeded, isError: isInviteError, error: inviteError },
  ] = OnboardingAPI.endpoints.requestTrialInvite.useMutation();

  const [
    createAccount,
    {
      isSuccess: accountCreationSucceeded,
      isError: isAccountError,
      error: accountError,
    },
  ] = OnboardingAPI.endpoints.singleStepAccountCreation.useMutation();

  useEffect(() => {
    if (accountCreationSucceeded) {
      dispatch(authenticate(email, password, false));
    }
  }, [dispatch, accountCreationSucceeded]);

  useEffect(() => {
    if (authToken && organizationUser) {
      history.push('/analyzer/settings');
    }
  }, [dispatch, authToken, organizationUser]);

  const handleSendInvite = async (evt: FormEvent) => {
    evt.preventDefault();
    await createTrialInvite({
      firstName,
      lastName,
      email,
      accountType: AccountType.Free,
      source: AccountRequestSource.Analyzer,
      captchaToken: token,
    });
  };

  const handleCreateAccount = async (evt: FormEvent) => {
    evt.preventDefault();
    await createAccount({
      firstName,
      lastName,
      email,
      verificationCode,
      password,
      passwordConfirm,
      source: AccountRequestSource.Analyzer,
    });
    evt.preventDefault();
  };

  const renderAccountErrorTemplate = (): ReactElement => {
    const errorStatus = (accountError as { status: number }).status;
    if (errorStatus === 401) {
      return (
        <div className="error alert alert-danger">
          Invalid code. Please check your input and try again.
        </div>
      );
    } else {
      return (
        <div className="error alert alert-danger">
          {t('error.genericErrorOccurred')}
        </div>
      );
    }
  };

  const renderInviteErrorTemplate = (): ReactElement => {
    const errorStatus = (inviteError as { status: number }).status;
    if (errorStatus === 409) {
      return (
        <div className="error alert alert-danger">
          This email is already in use.
          <br />
          <Link to="/analyzer/login">Click here to log in</Link> or contact
          support@pluralpolicy.com.
        </div>
      );
    } else {
      return (
        <div className="error alert alert-danger">
          {t('error.genericErrorOccurred')}
        </div>
      );
    }
  };

  return (
    <div className="login-card-wrapper">
      <Link to="/analyzer/">
        <img className="close-icon" src={closeIcon} />
      </Link>
      <div className="login-card">
        <img src={icon} />
        <h2>Plural Policy Analyzer</h2>

        {inviteCreationSucceeded ? (
          <>
            <p>
              Please enter the verification code we sent to <b>{email}</b>
            </p>
            <div className="error-message">
              {isAccountError && renderAccountErrorTemplate()}
            </div>
            <form className="login-form">
              <input
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Verification Code"
                type="text"
                value={verificationCode}
              />
              <input
                disabled
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                type="text"
                value={firstName}
              />
              <input
                disabled
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                type="text"
                value={lastName}
              />
              <input
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
                type="password"
                value={password}
              />
              <input
                onChange={(e) => setPasswordConfirm(e.target.value)}
                placeholder="Confirm Password"
                type="password"
                value={passwordConfirm}
              />
              <label className="terms-acceptance">
                <input
                  checked={termsAcceptance}
                  onChange={() => setTermsAcceptance(!termsAcceptance)}
                  type="checkbox"
                ></input>{' '}
                I Agree to Plural's&nbsp;{' '}
                <a href="https://pluralpolicy.com/terms-of-use/" target="_blank">
                  Terms of Use
                </a>
              </label>
              <button
                className="button dark-green"
                disabled={!termsAcceptance}
                onClick={handleCreateAccount}
              >
                Get Started
              </button>
            </form>
          </>
        ) : (
          <>
            <p>
              Analyze thousands of words of bill text in an instant – that’s the power
              of Plural.
            </p>
            <p>
              <strong>Access for $29/month</strong>
            </p>
            <div className="buttons">
              <a
                className="login-button google"
                href={`${process.env.REACT_APP_API_URL}/v2/auth/google`}
                role="button"
              >
                <span className="google-text">Sign up now with Google</span>
              </a>
            </div>

            <div className="separator">or</div>

            <div className="error-message">
              {isInviteError && renderInviteErrorTemplate()}
            </div>

            <form className="login-form">
              <input
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                type="text"
                value={firstName}
              />
              <input
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                type="text"
                value={lastName}
              />
              <input
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email address"
                type="text"
                value={email}
              />
              <TurnstileCaptcha onSuccess={setToken} />

              <button className="button" onClick={handleSendInvite}>
                Sign Up
              </button>
            </form>

            {/* Hidden in CSS for now, though maybe we bring this illustration
            back in the next design revision */}
            <div className="process">
              <span>
                <img src={filledDot} />
                Sign Up
              </span>
              <span>
                <img src={emptyDot} />
                Account Set Up
              </span>
              <span>
                <img src={emptyDot} />
                Search & Analyze
              </span>
            </div>
          </>
        )}

        <div className="login-link">
          <Link to="/analyzer/login">Already have a Plural account? Log in here</Link>
        </div>
      </div>
    </div>
  );
};

export default AnalyzerSignupCard;
